import { User } from "./ApiTypes";

export function assert(condition: any, message: any) {
    if (!condition) {
        throw new Error(message);
    }
}

// Errors
export class NotFoundError extends Error { }

export class ValidationError extends Error { }

export class QueryError extends Error { }

export class UnauthorizedError extends Error {
    constructor(message?: string) {
        super();
        this.message = message ?? "Unauthorized";
    }
}

export class InvalidRequestError extends Error {
    constructor(message?: string) {
        super();
        this.message = message ?? "Invalid Request";
    }
}

export class InternalServerError extends Error {
    constructor(message?: string) {
        super();
        this.message = message || "Internal Server Error";
    }
}

export class ForbiddenError extends Error {
    constructor(message?: string) {
        super();
        this.message = message || "Forbidden Error";
    }
}



// Throws a ValidationError if email is invalid
export function validateEmail(email: any) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(email).toLowerCase())) {
        return true;
    } else {
        throw new ValidationError(`Invalid email: ${email}`);
    }
}

export const capitalizeFirstLetter = (name: string): string => {
    return name ? `${name.charAt(0).toUpperCase()}${name.substr(1)}` : name;
}

// Throws a Validation error if value is "falsy"
export function validateRequired(fieldName: any, value: any) {
    if (value) {
       return true;
    } else {
        throw new ValidationError(`Invalid ${fieldName}: "${value}"`);
    }
}

export function removeEndingForwardSlash(input: string) {
    if (input.endsWith('/')) {
        return input.slice(0, -1);
    }
    return input;
}

// Query params for modals
export const MINDFULBREAK_MODAL = "mindfulbreak";
export const MONTHLYASSESSMENT_MODAL = "monthlyassessment";
export const LIVECHAT_MODAL = "live-chat";
// Paths
export const HOME_PATH = "/";
export const RESOURCES_PATH = "/resources";
export const RESOURCES_PATH_DIVISION = "/resources";
export const RESOURCES_PATH_MODULE = "/resources/:divisionId";
export const RESOURCES_PATH_CATEGORY = "/resources/:divisionId/:moduleId";
export const RESOURCES_PATH_ARTICLE = "/resources-article/:articleId";
export const BENEFITS_PATH = "/benefits";
export const DAILY_JOURNEY_PATH = "/daily-journey";
export const DAILY_VIDEO_PATH = "/daily-video";
export const SEARCH_PATH = "/search";
export const SEARCH_PATH_QUERY = "/search/:query";
export const WATCH_PATH = "/watch/:id";
export const PARTIAL_WATCH_PATH = "/watch/:playlistId/:id";
export const PlAYLIST_PATH = "/playlist/:id";
export const CATEGORY_PATH = "/category/:id";
export const SETTINGS_PATH = "/settings";
export const DEVELOPER_PATH = "/developer";
export const REGISTER_PATH = "/register/:companyCode";
export const REGISTER_COMPANY_SELECTOR_PATH = "/register";
export const LIVE_VIDEO_PATH="/watch-live/:id";
export const PUBLIC_WATCH_PATH="/video/public/:id";
export const COMMUNICATIONS_OPT_IN_PATH="/communications-opt-in";
export const CHAMPION_OPT_IN_PATH="/mhamc-champion-opt-in";
export const PARTICIPANT_OPT_IN_PATH="/mhamc-participant-opt-in";
export const LANGUAGES_PATH = "/languages";
export const SSO_LOGIN_PATH = "/auth/sso/:companyCode";
export const TEACHER_PATH = "/teacher/:id";
export const MY_LIST_PATH="/watch/mylist";
export const GUINESS_RECORD_CHALLENGE_PATH="/world-record-attempt";
export const LANDING_PAGE_PATH = "/landing/:name";
export const PERSONAL_PLAN_PATH = '/personal-plan';
export const PROFILE_PATH = '/profile';
export const ASSESSMENT_PATH = '/assessment';
export const SCHEDULE_APPOINTMENT_PATH = "/schedule-appointment";
export const HR_HUB_PAGE_PATH = "/hr-hub";
export const ASK_A_QUESTION_PATH="/ask-a-question";
export const PROACTIVE_CLINICAL_CARE_PATH="/proactive-clinical-care";
export const EAP_SUPPORT_PHONES_PATH = "/call-support";
export const TOPIC_PAGE_PATH = "/topic/:topicId";
// Labels
export const HOME_LABEL = "Home";
export const FOR_YOU_LABEL = 'For You';
export const BROWSE_LABEL = "Explore";
export const CHECKIN_LABEL = "Check-In";
export const DAILY_JOURNEY_LABEL = "Daily Journey";
export const DAILY_VIDEO_LABEL = "Mindful Break";
export const RECOMMENDATIONS_LABEL = "Recommendations";
export const RESOURCES_LABEL = "Resources";
export const BENEFITS_LABEL = "Benefits";
export const SETTINGS_LABEL = "Settings";
export const DEVELOPER_LABEL = "Developer";
export const LANGUAGES_LABEL = "Languages";
export const INSTANT_HELP_LABEL = "Get Help Now";
export const PROFILE_LABEL = "Profile";
export const PERSONAL_PLAN_LABEL = "Personal Plan"

// Async Worker names
export const DELETE_USERS = 'delete_users';
export const DELETE_USER_BY_ID = 'delete_single_user';
export const REINSTATE_USER_BY_ID = 'reinstate_user';
export const SEND_INVITATION = 'send_invitation';
export const IMPORT_VIDEO_FROM_S3 = 'import_video_from_s3';

// Report names
export const REPORT_BY_USER = 'user_report';

export const LOW_TIER_TRESHOLD = 1000;
export const HIGH_TIER_TRESHOLD = 5000;

// Mobile dimensions constants
export const MOBILE_MENU_TRESHOLD = 1180;

export const TEACHER = 'Teacher';
export const DURATION = 'Duration';
export const THEME = 'Theme';
export const IGNORE = 'Ignore';
export const FORMAT = 'Format';
export const SPECIAL = 'Special';
export const REPLAY_PLAYLIST_ID = 258390;
export const LIVE_PLAYLIST_ID = 245157;
export const PSEUDO_VOD_PLAYLIST_ID = 10; //random arbitrarily chosen id
export const PSEUDO_AUDIO_PLAYLIST_ID = 11; //random arbitrarily chosen id
export const AUDIO_PLAYLIST_IDS = [302264,287196, 287188, 287310, 287146, 287382];
export const BACKGROUND_TILE_COLOR = ["blue", "yellow", "purple", "green", "pink"];

export const PLATFORMS = ['iphone', 'ipad', 'web', 'android'];

export const DISCLAIMER = "This information will not be shared with your employer in a personally identifiable way. All questions are optional.";

export const LIVE_STREAM_CATEGORY_NAME = "Live Classes: Connect LIVE With Our Expert Teachers & Supportive Community";

export const LIVE_STREAM_CATEGORY_INTERNAL_NAME = "live-streams";
export const LIVE_STREAM_RECORDINGS_CATEGORY_INTERNAL_NAME = "live-streams-recordings";
export const DAILY_PRACTICE_PLAYLIST_SLUG = 'daily-practice';
export const ZOOM_APP_PLAYLIST_SLUG = 'zoom-app'

export function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const MONTHLY_ACTIVITY_INTENSITY_LOW = 1;
export const MONTHLY_ACTIVITY_INTENSITY_MEDIUM = 2;
export const MONTHLY_ACTIVITY_INTENSITY_HIGH = 3;

// Slack Block Messages
//Survey use case
export const INITIAL_MESSAGE = '[{"type": "actions","block_id":"initial_actions","elements": [{"type": "button","text": {"type": "plain_text","text": "Check In","emoji": true},"value": "checkin_button","action_id": "checkin_id"},{"type": "button","text": {"type": "plain_text","text": "Buddy-System Nudge","emoji": true},"value": "buddy_button","action_id": "buddy_id"},{"type": "button","text": {"type": "plain_text","text": "Survey","emoji": true},"value": "survey_button",	"action_id": "survey_id"}]}]';
export const SURVEY_OPTIONS = '[{"type": "actions","block_id":"survey_actions","elements": [{"type": "button","text": {"type": "plain_text","text": "Launch engagement survey","emoji": true},"value": "journey_survey_button",	"action_id": "journey_survey_id"},{"type": "button","text": {"type": "plain_text","text": "Create my own survey","emoji": true},"value": "custom_survey_button","action_id": "custom_survey_id"}]}]';
export const JOURNEY_SURVEY_MODAL = '[ { "type": "section", "text": { "type": "plain_text", "text": "Great, follow the next 3 steps to launch the survey:", "emoji": true } }, { "type": "divider" }, { "type": "section", "text": { "type": "mrkdwn", "text": "*question*" } }, { "type": "section", "text": { "type": "mrkdwn", "text": "*Select a channel*" } }, { "type": "actions", "elements": [ { "type": "channels_select", "placeholder": { "type": "plain_text", "text": "Select a channel", "emoji": true }, "initial_channel": "C12345678", "action_id": "channel_select" } ] }, { "type": "input", "element": { "type": "static_select", "placeholder": { "type": "plain_text", "text": "Select duration", "emoji": true }, "options": [ { "text": { "type": "plain_text", "text": "2 minutes", "emoji": true }, "value": "2" }, { "text": { "type": "plain_text", "text": "1 hour", "emoji": true }, "value": "60" }, { "text": { "type": "plain_text", "text": "8 hours", "emoji": true }, "value": "480" }, { "text": { "type": "plain_text", "text": "24 hours", "emoji": true }, "value": "1440" } ], "action_id": "duration_minutes" }, "label": { "type": "plain_text", "text": "Survey duration", "emoji": true } } ]';
export const CUSTOM_SURVEY_MODAL = '[{"type":"section","text":{"type":"plain_text","text":"Great, follow the next 3 steps to launch the survey:","emoji":true}},{"type":"divider"},{"type":"input","element":{"type":"plain_text_input","action_id":"question_input"},"label":{"type":"plain_text","text":"What do you want to ask your team? (Remember, answers will be measured in the Strongly Agree to Strongly Disagree scale)","emoji":true}},{"type":"input","element":{"type":"plain_text_input","action_id":"video_url_input"},"label":{"type":"plain_text","text":"What link do you want to share with your team when they complete the survey?","emoji":true}},{"type":"section","text":{"type":"mrkdwn","text":"*Select a channel*"}},{"type":"actions","elements":[{"type":"channels_select","placeholder":{"type":"plain_text","text":"Select a channel","emoji":true},"initial_channel":"C12345678","action_id":"channel_select"}]},{"type":"input","element":{"type":"static_select","placeholder":{"type":"plain_text","text":"Select duration","emoji":true},"options":[{ "text": { "type": "plain_text", "text": "2 minutes", "emoji": true }, "value": "2" }, { "text": { "type": "plain_text", "text": "1 hour", "emoji": true }, "value": "60" }, { "text": { "type": "plain_text", "text": "8 hours", "emoji": true }, "value": "480" }, { "text": { "type": "plain_text", "text": "24 hours", "emoji": true }, "value": "1440" }],"action_id":"duration_minutes"},"label":{"type":"plain_text","text":"Survey duration","emoji":true}}]';
export const SURVEY = '[{"type":"section","text":{"type":"mrkdwn","text":"*question*"},"accessory":{"type":"radio_buttons","options":[{"text":{"type":"plain_text","text":"Strongly agree","emoji":true},"value":"5"},{"text":{"type":"plain_text","text":"Agree","emoji":true},"value":"4"},{"text":{"type":"plain_text","text":"Neutral","emoji":true},"value":"3"},{"text":{"type":"plain_text","text":"Disagree","emoji":true},"value":"2"},{"text":{"type":"plain_text","text":"Strongly disagree","emoji":true},"value":"1"}],"action_id":"survey_vote"},"block_id":"*surveyId*"}]';
export const SURVEY_RESULTS = '[{"type":"header","text":{"type":"plain_text","text":"Results","emoji":true}},{"type":"section","text":{"type":"plain_text","text":"*question*","emoji":true}},{"type":"section","text":{"type":"mrkdwn","text":"Strongly agree: *strongly_agree_vote*"}},{"type":"section","text":{"type":"mrkdwn","text":"Agree: *agree_vote*"}},{"type":"section","text":{"type":"mrkdwn","text":"Neutral: *neutral_vote*"}},{"type":"section","text":{"type":"mrkdwn","text":"Disagree: *disagree_vote*"}},{"type":"section","text":{"type":"mrkdwn","text":"Strongly disagree: *strongly_disagree_vote*"}}]';
export const SHARE_SURVEY_RESULTS = '[{"type":"section","text":{"type":"plain_text","text":"Do you want to share the answers with your team?","emoji":true}},{"type":"actions","block_id":"initial_actions","elements":[{"type":"button","text":{"type":"plain_text","text":"Yes","emoji":true},"value":"share_yes_button","action_id":"share_yes_id"},{"type":"button","text":{"type":"plain_text","text":"No","emoji":true},"value":"share_no_button","action_id":"share_no_id"}]}]';
export const SURVEY_CLOSED = '[{"type":"section","text":{"type":"mrkdwn","text":"*question*"}},{"type":"context","elements":[{"type":"plain_text","text":"Survey has been closed!","emoji":true}]}]';
//Buddy use case
export const BUDDY_OPTIONS = '[{"type":"actions","block_id":"survey_actions","elements":[{"type":"button","text":{"type":"plain_text","text":"Pair Buddies","emoji":true},"value":"pair_buddies_button","action_id":"pair_buddies_id"},{"type":"button","text":{"type":"plain_text","text":"No thanks","emoji":true},"value":"cancel_buddies_button","action_id":"cancel_buddies_id"}]}]';
export const BUDDY_CONFIRMATION = '[{"type":"actions","elements":[{"type":"channels_select","placeholder":{"type":"plain_text","text":"Select a channel","emoji":true},"action_id":"channel_select"}]},{"type":"actions","block_id":"survey_actions","elements":[{"type":"button","text":{"type":"plain_text","text":"Launch Buddy System","emoji":true},"value":"launch_buddies_button","action_id":"launch_buddies_id"},{"type":"button","text":{"type":"plain_text","text":"Cancel","emoji":true},"value":"cancel_buddies_button","action_id":"cancel_buddies_id"}]}]';
export const BUDDY_OPT_OUT = '[{"type":"section","text":{"type":"mrkdwn","text":"Hi *name*, you are being paired with a buddy this week, we will nudge you throughout the week for you to reach out and checkin on them. Proactively taking care of our mental health works better, together."}},{"type":"actions","block_id":"initial_actions","elements":[{"type":"button","text":{"type":"plain_text","text":"Opt out","emoji":true},"value":"opt_out_button","action_id":"opt_out_id"}]}]';
export const BUDDY_REMINDER = '[{"type":"section","text":{"type":"mrkdwn","text":"Hi *name*, this is your reminder to reach out to your buddy *buddy_id*"}},{"type":"actions","block_id":"initial_actions","elements":[{"type":"button","text":{"type":"plain_text","text":"Completed","emoji":true},"value":"buddy_completed_button","action_id":"buddy_completed_id"}]}]';
//Checkin use case
export const CHECKIN_CONFIRMATION = '[{"type":"actions","block_id":"survey_actions","elements":[{"type":"button","text":{"type":"plain_text","text":"Launch Check-in","emoji":true},"value":"launch_checkin_button","action_id":"launch_checkin_id"},{"type":"button","text":{"type":"plain_text","text":"Cancel","emoji":true},"value":"cancel_checkin_button","action_id":"cancel_checkin_id"}]}]';
export const CHECKIN_MESSAGE = '[{"type":"section","text":{"type":"mrkdwn","text":"Hi *name*, how are you feeling today?"}},{"type":"actions","block_id":"initial_actions","elements":[{"type":"button","text":{"type":"plain_text","text":":smile:","emoji":true},"value":"great_button","action_id":"great_id"},{"type":"button","text":{"type":"plain_text","text":":slightly_smiling_face:","emoji":true},"value":"good_button","action_id":"good_id"},{"type":"button","text":{"type":"plain_text","text":":neutral_face:","emoji":true},"value":"ok_button","action_id":"ok_id"},{"type":"button","text":{"type":"plain_text","text":":white_frowning_face:","emoji":true},"value":"bad_button","action_id":"bad_id"}]}]';
export const CHECKIN_REMINDER_MESSAGE = '[{"type":"section","text":{"type":"mrkdwn","text":"Hey *name*, just reminding you that you can answer your Daily Check-in here"}},{"type":"actions","block_id":"initial_actions","elements":[{"type":"button","text":{"type":"plain_text","text":":smile:","emoji":true},"value":"great_button","action_id":"great_id"},{"type":"button","text":{"type":"plain_text","text":":slightly_smiling_face:","emoji":true},"value":"good_button","action_id":"good_id"},{"type":"button","text":{"type":"plain_text","text":":neutral_face:","emoji":true},"value":"ok_button","action_id":"ok_id"},{"type":"button","text":{"type":"plain_text","text":":white_frowning_face:","emoji":true},"value":"bad_button","action_id":"bad_id"}]}]';
export const JOURNAL_OPEN = '[{"type":"input","element":{"type":"plain_text_input","multiline":true,"action_id":"journal_input"},"label":{"type":"plain_text","text":"Use this space to reflect about what aspects of your life (work, family, finances, for example) are contributing to the way you are feeling. Remember, your answer is private","emoji":true},"block_id":"journal_text"},{"type":"actions","block_id":"initial_actions","elements":[{"type":"button","text":{"type":"plain_text","text":"Submit","emoji":true},"value":"submit_journal_button","action_id":"submit_journal_id"},{"type":"button","text":{"type":"plain_text","text":"Ignore","emoji":true},"value":"ignore_journal_button","action_id":"ignore_journal_id"}]}]';
export const JOURNAL_CLOSED = '[{"type":"section","text":{"type":"mrkdwn","text":"*Use this space to reflect about what aspects of your life (work, family, finances, for example) are contributing to the way you are feeling. Remember, your answer is private*"}},{"type":"actions","elements":[{"type":"button","text":{"type":"plain_text","text":"Open dialog","emoji":true},"value":"open_dialog_button","action_id":"open_dialog_id"}]}]';
export const JOURNAL_SUBMITTED = '[{"type":"section","text":{"type":"mrkdwn","text":"*Use this space to reflect about what aspects of your life (work, family, finances, for example) are contributing to the way you are feeling. Remember, your answer is private*"}},{"type":"section","text":{"type":"plain_text","text":"*journal_answer*","emoji":true}}]';

// Slack Replace Identifiers
export const QUESTION_IDENTIFIER = "*question*";
export const SURVEY_ID_IDENTIFIER = "*surveyId*";
export const STRONGLY_AGREE_IDENTIFIER = "*strongly_agree_vote*";
export const AGREE_IDENTIFIER = "*agree_vote*";
export const NEUTRAL_IDENTIFIER = "*neutral_vote*";
export const DISAGREE_IDENTIFIER = "*disagree_vote*";
export const STRONGLY_DISAGREE_IDENTIFIER = "*strongly_disagree_vote*";
export const USER_NAME_IDENTIFIER = "*name*";
export const JOURNAL_ANSWER_IDENTIFIER = "*journal_answer*";
export const BUDDY_ID_IDENTIFIER = "*buddy_id*";

// Slack Scheduled Messages Types
export const SURVEY_CLOSED_TYPE = "SURVEY_CLOSED";
export const BUDDY_REMINDER_TYPE = "BUDDY_REMINDER";
export const CHECKIN_REMINDER_TYPE = 'CHECKIN_REMINDER';

// Community charts
export const BAR_CHART_COLOR = ['#4EDED6', '#FFA798', '#77CAF1', '#FFCD70'];

export const BUDDY_LAUNCH_TYPE = 'BUDDY_LAUNCH';

// Privacy Policy URL
export const PRIVACY_POLICY_URL='https://journey.live/privacy';


export function getTranslatedGreeting(currentUser: User | null, t: (key: string) => string) {
    const now = new Date();
    const ending = currentUser ? `, ${currentUser.firstName}.` : ".";
    return now.getHours() < 5 ? t("Hi Night Owl") :
        now.getHours() < 12 ? `${t("Good Morning")}${ending}` :
            now.getHours() < 17 ? `${t("Good Afternoon")}${ending}`
                : `${t("Good Evening")}${ending}`;
}