import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {
    IonButton,
    IonContent,
    IonIcon,
    IonPage,
    isPlatform
} from "@ionic/react";
import { removeCircle, addCircle } from 'ionicons/icons';
import "./TopicPage.scss";
import { useHistory, useLocation, useParams} from "react-router-dom";
import {JourneyApiClient} from "../../utils/JourneyApiClient";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";
import { ResponsiveNavigationMenu } from '../../components/Navigation/ResponsiveNavigationMenu';
import { Feature } from '../../components/Feature/Feature';
import { TalkToSomeone } from '../../components/TalkToSomeone/TalkToSomeone';
import { useTranslation } from 'react-i18next';
import { TopicComponent } from '../../components/Topic/TopicComponent';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { SearchBar } from '../../components/SearchBar/SearchBar';
import AnalyticsService from '../../misc/AnalyticsService';
import { ApiTopic, CollectionType, ApiAsset, SearchResultContentItem, ApiCollection } from '../../utils/ApiTypes';
import { ChipFilter } from '../../components/ChipFilter/ChipFilter';
import ContentGrid from '../../components/ContentGrid/ContentGrid';
import AlgoliaFilterHelper from '../../misc/AlgoliaFilterHelper';
import { HOME_PATH, sleep } from '../../utils/utils';

type TopicRouteParams = {
    topicId: string;
};

export const TopicPage: React.FC = () => {

    const {isMobileWidth, handleGeneralError, currentUser, handleGeneralMessage, handleApplicationError } = useContext(ApplicationContext);
    const [topicId, setTopicId] = useState<string | null>(null);
    const [currentSearchString, setCurrentSearchString] = useState<string | null>(null);
    const [algoliaFilterForTopicContent, setAlgoliaFilterForTopicContent] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFollowingTopic, setIsFollowingTopic] = useState<boolean>(false);
    const [contentAvailable, setContentAvailable] = useState<boolean>(false);
    const [topic, setTopic] = useState<ApiTopic | null>(null);
    const [content, setContent] = useState<SearchResultContentItem[] | null>(null);
    const params = useParams<TopicRouteParams>();
    const isComponentVisible = useIsComponentVisible();
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const RESOURCE_TYPES = [(currentSearchString ? t("All") : t("Highlights")), t("Video"), t("Audio"), t("Articles")];
    const [resourceCountMap, setResourceCountMap] = useState<Map<string, number>>(new Map());
    const [selectedResourceType, setSelectedResourceType] = useState<string>(RESOURCE_TYPES[0]);  

    useEffect(() => {
        if (!isComponentVisible) return;
        setTopicId(params.topicId);
    }, [isComponentVisible]);

    async function getTopicContent(topicId: string): Promise<ApiAsset | undefined> {
        try {
            let result = await JourneyApiClient.getAsset(topicId);
            switch(result?.type){
                case CollectionType.Topic:
                    setTopic(result as ApiTopic);
                    setIsFollowingTopic(result.isSaved);
                    //Sending topic's content ids to filter search results withing those ids
                    setAlgoliaFilterForTopicContent(AlgoliaFilterHelper.createFilterString(result, "OR"));
                    //Checking if topic's collections exist and have content
                    if(result.items.length === 0 || result.items.every(collection => (collection as ApiCollection).items.length === 0)){
                        handleApplicationError("This topic does not have any content yet.");
                        redirectHome();
                    } else {
                        setContentAvailable(true);
                    }
                    break;
                default:
                    //Not a topic, we return to homepage
                    handleApplicationError("Content fetched is not of type topic");
                    redirectHome();
            }
            return result;
        } catch (e) {
            handleGeneralError("Could not fetch topic, redirecting to homepage", e);
            redirectHome();
        }
    }

    function redirectHome(){
        sleep(2000).then(() => {
            history.push(HOME_PATH);
        });
    }

    const topicQuery = useQuery<Promise<ApiAsset | undefined>, unknown, ApiAsset>
        (["getTopic", topicId], () => getTopicContent(topicId!), { enabled: !!topicId });    

    const followTopic = useMutation({
        mutationFn: ({playlistId, isFollowingTopic }: {playlistId: number, isFollowingTopic: boolean}) => { 
            if(isFollowingTopic){
                return JourneyApiClient.removeSavedVideoPlaylist(playlistId).then(() => {
                    handleGeneralMessage("Success", `${topic?.title} is now removed from your favorites.`);
                    setIsFollowingTopic(false);
                });
            } else {
                return JourneyApiClient.saveVideoPlaylist(playlistId).then(() => {
                    handleGeneralMessage("Success", `${topic?.title} is now added to your favorites.`);
                    setIsFollowingTopic(true);
                });
            }
        },
        onSuccess: async () => {
            queryClient.invalidateQueries(["getTopic"]);
        }
    });

    function handleFollowClick (isFollowing: boolean, tId: number | string) {
        //Adding .toString() to tId to avoid type error
        const topicId: number = ((typeof tId) === 'string' ? parseInt(tId.toString()) : tId as number);
        try {
            followTopic.mutate({playlistId: topicId, isFollowingTopic: isFollowing});
            AnalyticsService.trackUserAction(isFollowing ? "removed_save_topic" : "topic_saved", location.pathname, {topic_id: topicId });

        } catch (ex) {
            handleGeneralError(`Error ${isFollowing ? "removing": "adding"} a topic from 'My List'`, ex);
        }
    }

    async function trackSearchString(keyword: string) {
        await AnalyticsService.trackUserAction("search_topic_content", location.pathname, {keyword});
    }

    function clearSearch() {
        setContent(null);
        setCurrentSearchString(null);
    }
        
    const searchContent = (searchString: string | null) => {
        if(searchString){
            setIsLoading(true);
            setCurrentSearchString(searchString);
            const device = isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "other";
            
            JourneyApiClient.searchByTitle(searchString, device, algoliaFilterForTopicContent).then(list => {
                trackSearchString(searchString);
                setIsLoading(false);
                setContent(list);
            }).catch(error => {
                handleGeneralError("Could not fetch videos", error);
                setIsLoading(false);
            });
        }
    }

    return(
        <IonPage className={`topic-page`}>

            <ResponsiveNavigationMenu title={"Topics"} /> 

            {topicQuery.status === "success" && contentAvailable && topic ? <IonContent className="topics-content">
                <div className={"topic-top-container"}>

                    <div className={"topic-name page-title"}>
                        {t(`${topicQuery.data.title}`)}
                        
                        <IonButton onClick={() => handleFollowClick(isFollowingTopic, topicQuery.data.id)} className="follow-button" fill="clear">
                            <IonIcon icon={isFollowingTopic ? removeCircle : addCircle} size="large" slot="start" className="follow-icon"/>
                            <span className="button-small">{isFollowingTopic ? t("Unfollow") : t("Follow")}</span>
                        </IonButton>
                    </div>

                    {currentUser && <Feature feature="has-eap"> 
                        <TalkToSomeone
                            className={"topics-page-talk-someone"}
                            user={currentUser}
                            isMobileWidth={isMobileWidth}
                            showDescription={true}
                            showDescriptionInPopover={false}
                            isCondensed={false}
                            eapDescription={topic.eapDescription}
                            isComponentVisible={isComponentVisible} 
                            subtitle={t("Our Master's Level Clinicians are available 24/7 to support your needs. All support is free and confidential.")} />
                    </Feature>}
                    
                    <div className={"topic-filter-content-container"}>
                        <div className={"topic-search-container"}>
                            <div className={"overline search-container-header"}>
                                {t("Search for secific content")}
                            </div>
                            <SearchBar 
                                onNewSearchQuery={searchContent} 
                                isSearchLoading={isLoading}
                                onClearSearchQuery={clearSearch}
                            />
                        </div>
                        <hr className={"topic-page-divider"}/>
                        <div className="topic-chip-container">
                            <div className={"overline search-container-header"}>
                                {t("Filter by content type")}
                            </div>
                            {RESOURCE_TYPES.map((resourceType: string, index: number) => {
                                let resourceCount = resourceCountMap.has(resourceType) ? resourceCountMap.get(resourceType) : "";
                                return (
                                    <ChipFilter
                                        key={index}
                                        className={"topic-chip"}
                                        labelClassName={"topic-label-text button-small"}
                                        id={RESOURCE_TYPES.indexOf(resourceType).toString()}
                                        checkmarkIcon={true}
                                        handleChipClick={() => setSelectedResourceType(resourceType)}
                                        selectedChips={[RESOURCE_TYPES.indexOf(selectedResourceType).toString()]}
                                        name={`${resourceType} ${resourceCount ? `(${resourceCount})` : ""}`}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div> 
                
                <div>
                    {currentSearchString && isLoading ? <PleaseWait /> : currentSearchString && (content) ?
                        <div className={"topic-search-content-grid-wrapper"}>
                            <ContentGrid content={content} className={"search-page-content-grid-container"}/> 
                        </div> : 
                    <TopicComponent content={topic.items} contentTypeFilter={selectedResourceType} contentCount={setResourceCountMap}/>}
                </div> 

                

            </IonContent> : <PleaseWait />}
        </IonPage>
    )
};